<template>
  <v-container>
    <template v-if="res">
      <div class="d-flex flex-row align-center justify-space-between">
        <page-title :title="providerName" />
        <v-btn
          :disabled="status == 'success' ? true : false"
          depressed
          color="primary"
          small
          @click="showModal = !showModal"
          >已付款</v-btn
        >
      </div>

      <div class="mb-5">
        <p>
          <span class="primary--text"
            >NT${{ total }} / {{ status == 'success' ? updated_at : '' }}
            {{ `apply-pay.status.${status}` | t }} /</span
          >
          {{ `data.payment_type.${storePaymentType}` | t }}
        </p>
        <p>請款時間： {{ applyPayDate }}</p>
        <p>訂單日期： {{ orderDate }}</p>
      </div>

      <p class="pb-2 border-bottom-1 mb-2 primary--text">共{{ count }}筆訂單</p>
      <v-divider/>

      <div class="mb-10">
        <apply-pay-card
            v-for="(item, index) in cardData"
            :key="index"
            v-model="cardData[index]"
            :configs="cardConfigs(index)"
        ></apply-pay-card>
      </div>

      <number-bar
          title="應付金額"
          unit="元"
          :value="total"
          readonly
          type="secondary"
          class="mb-5"
      ></number-bar>

      <v-btn
          width="100%"
          @click="back"
          class="primary"
      >
        回應付款列表
      </v-btn>
    </template>

    <confirm-apply-pay-modal
      v-if="showModal"
      @apply="apply"
      @close="close"
    ></confirm-apply-pay-modal>
  </v-container>
</template>

<script>
import applyPayDetail from "mixins/applyPayDetail";
export  default  {
  mixins: [applyPayDetail],
  components: {
    'confirm-apply-pay-modal': () =>
      import('modules/store/views/applyPay/modal/confirmApplyPayModal.vue'),
  },
  data: () => ({
    showModal: false,
  }),
  computed: {
    storeId() {
      return this.$route.params.storeId
    },
    applyPayId() {
      return this.$route.params.id
    },
    providerId() {
      return this.$route.params.routeProviderId
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: 'store-apply-pay-overview',
        params: {
          providerId: this.providerId,
        },
      });
    },
    close() {
      this.showModal = false;
    },
    async apply(account_no) {
      this.$store.dispatch('loading/active');
      try {
        await this.$api.collection.applyPayApi.storeApplyPayUpdate({
          store_id: this.res.store_id,
          applyPay_id: this.applyPayId,
          account_no: account_no,
        });
        this.getData();
        this.$snotify.success('成功');
      } catch (err) {
        console.error(err);
        this.$snotify.error(err.data.message);
      } finally {
        this.$store.dispatch('loading/close');
        this.showModal = false;
      }
    },
  },
};
</script>
