import discountMixins from "./discount";
import countMixins from "./count";
import dayjs from "dayjs";

export default {
  mixins: [discountMixins, countMixins],
  components: {
    'apply-pay-card': () =>
      import('modules/base/components/priceCard/applyPay/applyPayCard.vue'),
  },
  data: () => ({
    res: null,
    updated_at: '',
  }),
  computed: {
    storeId() {
      return this.$route.params.storeId;
    },
    applyPayId() {
      return this.$route.params.id;
    },
    count() {
      if (!this.res) return 0;
      return this.res.count;
    },
    storeName() {
      if (!this.res) return null;
      return this.res.store_name;
    },
    providerName() {
      if (!this.res) return null;
      return this.res.provider_name;
    },
    status() {
      if (!this.res) return null;
      return this.res.status;
    },
    storePaymentType() {
      if (!this.res) return null;
      return this.res.store_payment_type;
    },
    applyPayDate() {
      if (!this.res) return null;
      return this.res.created_at;
    },
    orderDate() {
      if (!this.res) return null;
      const cloneItems = eagleLodash.cloneDeep(this.res.items).map((item) => {
        return dayjs(item.created_at).format('YYYY-MM-DD');
      });
      const set = [...new Set(cloneItems)];
      set.sort((a, b) => {
        if (dayjs(a).isAfter(dayjs(b))) return 1;
        if (dayjs(a).isBefore(dayjs(b))) return -1;
        return 0;
      });

      if (set.length == 1) return set[0];

      return [set[0], set[set.length - 1]].join('~');
    },
    data() {
      if (!this.res) return [];
      return this.res.items.map((item) => {
        return {
          order_id: item.order_no,
          date: item.created_at,
          price: item.amount,
        };
      });
    },
    cardData() {
      if (!this.data) return [];
      return this.data.map((item) => {
        return {
          order_id: item.order_id,
          price: item.price,
          date: item.date,
        };
      });
    },
    total() {
      if (!this.data) return 0;
      return this.data.reduce((acc, cur) => {
        return (acc += cur.price);
      }, 0);
    },
  },
  async created() {
    await this.getData();
    this.getStoreApplyPayIndex();
  },
  mounted() {
  },
  methods: {
    async getData() {
      this.$store.dispatch('loading/active');
      try {
        const res = await this.$api.collection.applyPayApi.read({
          provider: this.providerId,
          applyPayId: this.applyPayId,
          params: {},
        });

        this.res = res;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch('loading/close');
      }
    },
    cardConfigs(index) {
      if (!this.data) return [];
      const item = this.data[index];
      const configs = [
        {
          label: '訂單編號',
          value: item.order_id,
          class: 'secondary--text',
        },
        { label: '日期', value: item.date, col: 6 },
        {
          label: '金額',
          value: item.price,
          valueSuffix: '元',
          fixed: 2,
          col: 6,
          class: 'primary--text',
        },
      ];
      return configs;
    },
    async getStoreApplyPayIndex() {
      const {
        data: storeApplyPayIndex,
      } = await this.$api.collection.applyPayApi.storeApplyPayIndex({
        store: this.res.store_id,
        params: {},
      });

      this.updated_at = storeApplyPayIndex.find(
        (item) => item.applyPay_id == this.applyPayId
      ).updated_at;
    },
  },
};
